
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
import DomainItemInfo from './DomainItemInfo.vue'

@Component({
  components: {
    DomainItemInfo
  }
})
export default class DomainItem extends Vue {
  @Prop({ type: Object, required: true })
  private item!: Common.Domain.BaseInfo

  private get hasOwner () {
    return !!this.item.owner
  }

  private get isFavor () {
    return this.item.isFavor
  }

  private get unavilable () {
    return this.item.status === 'unavilable'
  }

  private get label () {
    return lang('DOMAIN_SEARCHPANEL_EXACTLY_MATCH')
  }

  private handlerClick () {
    const domain = encodeURIComponent(this.item.domain)
    this.$router.push(`/details/${domain}`).catch(e => console.log('[router]:' + e.message))
  }
}
