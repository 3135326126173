
import { Vue, Component, Watch } from 'vue-property-decorator'
import { searchDomain } from '@/sdk/domain'
import { wallet } from '@/utils/wallet'
import { lang } from '@/lang'
import { errorMessage } from '@/utils/message'
import { EventBus } from '@/utils/event'
import DomainItem from './components/DomainItem.vue'
import { maxLength, minLength } from '@/config/domainLength'

@Component({
  components: {
    DomainItem
  }
})
export default class SearchResult extends Vue {
  private loading = false
  private domainInfo: Common.Domain.BaseInfo | null = null

  private get keyword () {
    return (this.$route.query.keyword as string) || ''
  }

  private searchSign!: symbol

  private async getDomainInfo () {
    const sign = this.searchSign = Symbol('searchSign')
    this.loading = true
    await searchDomain(this.keyword)
      .then((data) => {
        if (sign === this.searchSign) {
          this.domainInfo = data
        }
      })
      .catch(e => {
        if (e.message === 'too short') {
          errorMessage(lang('NAME_REGISTER_TOO_SHORT').replace('#', minLength.toString()))
        }

        if (e.message === 'too long') {
          errorMessage(lang('NAME_REGISTER_TOO_LONG').replace('#', maxLength.toString()))
        }

        if (e.message === 'invalid character') {
          errorMessage(lang('NAME_REGISTER_INVALID_CHARACTER'))
        }

        if (e.message === 'User reject request') {
          errorMessage(e.message)
        }
      })
      .finally(() => {
        if (sign === this.searchSign) {
          this.loading = false
        }
      })
  }

  async mounted (): Promise<void> {
    if (this.keyword) {
      await this.getDomainInfo()
    }
    this.$eventBus.on(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainInfo)
    this.$once('hook:beforeDestroy', () => {
      this.$eventBus.off(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainInfo)
    })
  }

  @Watch('keyword')
  private onKeywordChange () {
    this.getDomainInfo()
  }
}
